<template>
  <div class="pharmaceutical">
    <div class="desc box_padding">
      <h3>行业难题</h3>
      <p>
        众所周知，中药（含中成药、中药饮片和颗粒剂等）是中医临床的实战工具和武器，其中的核心是中药材，“药材好，药才好”，中药材品质稳定高效，才能最大程度确保中医临床疗效。
      </p>
      <p>
        但中药材原料在生产流通过程中，环节极为繁多，采集、生产、加工、炮制、包装等，哪个环节出了问题，最终的中药商品质量都难以得到保障。
      </p>
      <div class="desc_img"></div>
      <p>
        行业信息化水平过低，中药材来源、生产过程、加工炮制等过程不透明，部分环节甚至处于失控状态，进而极大影响了中医临床用药的安全、有效和稳定。因此，开展中药全程质量追溯，首要目的就是要保障中医的临床疗效。
      </p>
      <p>
        "道地药材"不再"道地"异地种植令溯源失真。市场假货窜货行为危害人体健康，扰乱市场秩序，影响品牌形象。
      </p>
    </div>
    <div class="desc_2 box_padding">
      <h3>解决方案</h3>
      <div class="desc_img"></div>
      <p>
        药安佳溯源协同管理平台是基于物联网技术，应用于医药领域的大型综合云平台。以“一物一码”及互联网云为基础，实现从原料进厂、药品生产、商品配送、物流运输、终端销售各环节的全程追溯可视化。
      </p>

      <p>
        平台可协助企业快速建立满足国家与市场
        监管的全程追溯体系，同时帮助企业实现医药大健康产业环境下的大数据生态系统信息化管理，完善企业供应链各环节在计划、
        协调、操作、优化的活动与过程，并可实现高效、精准、灵活的营销策略分析与决策。
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Pharmaceutical",
};
</script>

<style lang="less" scoped>
.pharmaceutical {
  .box_padding {
    padding: 50px 30px;
  }
  h3 {
    font-size: 50px;
    font-weight: 700;
  }
  p {
    font-size: 35px;
    color: #333;
    margin: 20px 0;
    text-align: justify;
  }
  span {
    font-size: 35px;
    font-weight: 500;
  }

  .desc_img {
    width: 100%;
    height: 400px;
    border: 2px solid #f0f0f0;
    background: url("~@/assets/img/product/pharmaceutical_1.jpg") no-repeat
      center/cover;
    border-radius: 10px;
    margin: 10px 0;
  }
  .desc_2 {
    background-color: #f7f7f7;
    .desc_img {
      height: 250px;
      background: url("~@/assets/img/banner/pharmaceutical.jpg") no-repeat
        center/cover;
    }
  }
}
</style>